import React from 'react';
import { Router } from './Router.component';

function App() {
  return (
    <Router/>
  );
}

export default App;
